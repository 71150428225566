/* eslint-disable global-require */
import React from 'react';
import styled from 'styled-components';

import Section from '@latitude/section';
import { Text } from '@latitude/text';
import { Link } from '@latitude/link';
import { Promo } from '@latitude/promo';
import { COLOR, BUTTON_STYLE } from '@latitude/core/utils/constants';

import ingBookingComOffer from './images/booking.webp';
import imgExpediaWotifOffer from './images/expedia-wotif.webp';
import imgHotelsComOffer from './images/hotels-com.webp';
import imgLatestOffersPromo from './images/promo-latest-offers.webp';

function LatestOffersSection({ anchor }) {
  return (
    <>
      <Section
        id={anchor}
        heading="Latest offers"
        css={{
          backgroundColor: COLOR.WHITE
        }}
      >
        <Promo
          title="Get $200 bonus Latitude Rewards"
          description={
            <Text>
              When you spend $2,000 or more on eligible transactions every
              statement period in your first 3 months of approval. T&Cs, card
              fee and other charges apply. New and approved customers only.
              Offer ends 31 Dec 2024<sup>5</sup>.
            </Text>
          }
          frameImage={
            <img src={imgLatestOffersPromo} alt="Don't have a card yet?" />
          }
          verticalAlignContent
          frameAlignment="left"
          frameBorderColor="#0061EE"
          ctaButtons={
            <div>
              <Link
                button={BUTTON_STYLE.SECONDARY}
                href="https://cards.latitudefinancial.com/28degrees"
                target="_blank"
                trackId="latest-offers__apply-now"
              >
                Apply now
              </Link>
            </div>
          }
        />
      </Section>
      <Section
        css={{
          backgroundColor: COLOR.GREY6
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '50px',
            justifyContent: 'center',
            margin: '10pt auto',
            backgroundColor: COLOR.GREY6
          }}
        >
          <OfferItem>
            <img src={ingBookingComOffer} alt="Booking.com" />
            <Text>
              Offer valid from 17 Sep 2024 to 16 Dec 2024 and may be extended or
              withdrawn. Capped at $300 per booking. Exclusions and T&Cs apply.
            </Text>

            <Link
              button={BUTTON_STYLE.TERTIARY}
              href="/travelpartners/"
              data-trackid="latest-offers-book-now-bookingcom"
              trackEventData={{
                label: 'Learn more about latest offers'
              }}
            >
              Book now
            </Link>
          </OfferItem>
          <OfferItem>
            <img src={imgExpediaWotifOffer} alt="Expedia" />
            <Text>Offer may be withdrawn. Exclusions and T&Cs apply.</Text>

            <Link
              button={BUTTON_STYLE.TERTIARY}
              href="/travelpartners/"
              data-trackid="latest-offers-book-now-expedia"
              trackEventData={{
                label: 'Learn more about latest offers'
              }}
            >
              Book now
            </Link>
          </OfferItem>
          {/* <OfferItem>
            <img src={imgHotelsComOffer} alt="Hotels.com" />
            <Text>
              On selected hotels all day, everyday, year-round. Exclusions and
              T&Cs apply.
            </Text>

            <Link
              button={BUTTON_STYLE.TERTIARY}
              href="/travelpartners/"
              data-trackid="latest-offers-book-now-hotelscom"
              trackEventData={{
                label: 'Learn more about latest offers'
              }}
            >
              Book now
            </Link>
          </OfferItem> */}
        </div>
      </Section>
    </>
  );
}

const OfferItem = styled.div`
  background-color: #ffffff;
  flex-basis: 300px;
  margin: 0 0;
  padding: 0;
  border: 1px solid #ccc;
  border-bottom: 3px solid #888;
  p {
    text-align: center;
    padding: 20px;
    height: 180px;
  }
  a {
    width: fit-content;
    margin: 20px auto;
  }
  img {
    width: 100%;
    height: auto;
  }
`;

export default LatestOffersSection;
